import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { memberBreadcrumbItems, members } from 'data/members';
import MembersTable, {
    membersTablecolumns
} from 'components/tables/MembersTable';
import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';
import Avatar from 'components/base/Avatar';

let breadcrumbData = [
    { label: 'Setting', url: '#' },
    { label: 'Organization', active: true }
]

let tableColumn = [
    {
        accessorKey: 'name',
        header: 'Organization Name',
        cell: ({ row: { original } }) => {
            const { name, avatar } = original;
            return (
                <Link to="#!" className=" d-flex align-items-center text-body">
                    <Avatar src={avatar} size="m" />
                    <p className="mb-0 ms-3 text-body-emphasis fw-semibold">{name}</p>
                </Link>
            );
        },
        meta: {
            headerProps: {
                style: { width: '15%', minWidth: '200px' },
                className: 'px-1'
            },
            cellProps: { className: 'align-middle white-space-nowrap py-2' }
        }
    },
    {
        accessorKey: 'email',
        header: 'Organization Code',
        cell: ({ row: { original } }) => {
            const { email } = original;
            return (
                <Link to={`mailto:${email}`} className="fw-semibold">
                    {email}
                </Link>
            );
        },
        meta: {
            headerProps: {
                style: { width: '15%', minWidth: '200px' }
            },
            cellProps: { className: 'white-space-nowrap' }
        }
    },
]

const OrganizationList = () => {
    let navigate = useNavigate();
    const table = useAdvanceTable({
        data: members,
        columns: tableColumn,
        pageSize: 10,
        pagination: true,
        sortable: true,
        selection: true
    });

    const handleSearchInputChange = (e) => {
        table.setGlobalFilter(e.target.value || undefined);
    };

    return (
        <div>
            <PageBreadcrumb items={breadcrumbData} />
            <div className="mb-9">
                <h2 className="mb-5">Organization</h2>

                <AdvanceTableProvider {...table}>
                    <div className="mb-4">
                        <Row className="g-3">
                            <Col xs="auto">
                                <SearchBox
                                    placeholder="Search members"
                                    onChange={handleSearchInputChange}
                                />
                            </Col>
                            <Col
                                xs="auto"
                                className="scrollbar overflow-hidden-y flex-grow-1"
                            ></Col>
                            <Col xs="auto">
                                <Button variant="link" className="text-body me-4 px-0">
                                    <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                                    Export
                                </Button>
                                <Button variant="primary" onClick={()=>{ navigate('/organization/new')}}>
                                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                                    Add organization
                                </Button>
                            </Col>
                        </Row>
                    </div>

                    <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
                        <MembersTable />
                    </div>
                </AdvanceTableProvider>
            </div>
        </div>
    );
};

export default OrganizationList;
