import logo from 'assets/img/logos/logoIcon.png';
const PageLoader = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100 w-100 ">
      <div>
        <img src={logo} alt="Milove Logo" className="logo-ripple ripple-1" />
        <img src={logo} alt="Milove Logo" className="logo-ripple ripple-2" />
        <img src={logo} alt="Milove Logo" className="logo-ripple ripple-3" />
        <img src={logo} alt="Milove Logo" className="logo-ripple ripple-4" />
        <img src={logo} alt="Milove Logo" className="logo-ripple ripple-5" />
      </div>
    </div>
  );
};

export default PageLoader;
