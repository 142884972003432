import AppProvider from 'providers/AppProvider';
import React from 'react';
import ReactDOM from 'react-dom/client';
import BreakpointsProvider from 'providers/BreakpointsProvider';
import SettingsPanelProvider from 'providers/SettingsPanelProvider';
import { RouterProvider } from 'react-router-dom';
import { router } from 'Routes';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import ChatWidgetProvider from 'providers/ChatWidgetProvider';
import Index from './page/index';
// import AgoraRTC, { AgoraRTCProvider } from "agora-rtc-react";
// import Testing from 'testing';

// const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <>
    {/* <AgoraRTCProvider client={client}> */}
    <Provider store={store}>
      <AppProvider>
        <SettingsPanelProvider>
          <ChatWidgetProvider>
            <BreakpointsProvider>
              {/* <Testing /> */}
              <Index />
              {/* <RouterProvider router={router} /> */}
            </BreakpointsProvider>
          </ChatWidgetProvider>
        </SettingsPanelProvider>
      </AppProvider>
    </Provider>
    {/* </AgoraRTCProvider> */}
  </>
  // </React.StrictMode>
);
