import { UPDATE_THEME } from "../actions/ThemeActions";

const defaultState = {
    theme:  "light"
};

function themeReducer(state = defaultState, action) {
    switch (action.type) {
        case UPDATE_THEME:
            return {
                ...state,
                theme: action.theme,
            };
        default:
            return state;
    }
}

export default themeReducer;