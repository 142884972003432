const EN = {
    "Sign In": "Sign In",
    "Get access to your account": "Get access to your account",
    "Email address": "Email address",
    "Password": "Password",
    "Remember me": "Remember me",
    "Forgot Password?": "Forgot Password?",
    "Create an account": "Create an account",
    "inprogress": "In Progress",
}

export default EN;