import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { faKey, faSpinner, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import AuthSocialButtons from 'components/common/AuthSocialButtons';
import { Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AuthCardLayout from 'layout/AuthCardLayout';
import { translate } from 'utils/translate';
import { useDispatch } from 'react-redux';
import { loadingToggleAction, loginAction } from 'store/actions/AuthActions';
import Logo from 'components/common/Logo';
import Peer from 'peerjs';


const Welcome = (props) => {
    const [rooms, setRooms] = useState(['5d046ba7-179e-4e83-8e72-b912a8507199', 'a6d37bf0-d20c-4d40-985e-28d925313ee5']);

    return (
        <div className='body2'>
            <div className="container-1">
                <div className='search-container'></div>
                <div className='icon'>1</div>
                <div className='icon'>2</div>
                <div className='icon'>3</div>
            </div>
            <div className="container-2">
                <div className='card'></div>
                <div className='card'></div>
            </div>
            <div className="container-3">
                <div>优质接待</div>
                <div className='container-3-card-container'>
                    <div className='card'></div>
                    <div className='card'></div>
                    <div className='card'></div>
                    <div className='card'></div>
                </div>
            </div>
            <div className="container-4">
                <div className='container-4-menu-container'>
                    <div className='menu'>我的</div>
                    <div className='menu active'>推荐</div>
                    <div className='menu'>女神</div>
                    <div className='menu'>男神</div>
                    <div className='menu'>聊天</div>
                    <div className='menu'>飙歌</div>
                    <div className='menu'>个人</div>
                </div>
                <div className='container-4-card-container'>
                    <Row>
                        {
                            rooms.map((item, index) => (
                                <Col key={index} xs={6}><Link to={`/room/${item}`} className='card'>{'...' + item.slice(-4)}</Link></Col>
                            ))
                        }
                    </Row>
                </div>
            </div>
            <div className='footer-navbar'>
                <Link to={`/meet`} className='nav'>偶遇</Link>
                <Link to={`/party`} className='nav'>星球</Link>
                <Link to={`/party`} className='nav'>派对</Link>
                <Link to={`/party`} className='nav'>消息</Link>
                <Link to={`/party`} className='nav'>我的</Link>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
        showLoading: auth.showLoading,
    }
}

export default connect(mapStateToProps)(Welcome);
