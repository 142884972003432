import { useState } from 'react';
import { connect } from 'react-redux';
import { faKey, faSpinner, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import AuthSocialButtons from 'components/common/AuthSocialButtons';
import { Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AuthCardLayout from 'layout/AuthCardLayout';
import { translate } from 'utils/translate';
import { useDispatch } from 'react-redux';
import { loadingToggleAction, loginAction } from 'store/actions/AuthActions';
import Logo from 'components/common/Logo';


const Meet = (props) => {
    const dispatch = useDispatch();
    let errorsObj = { username: '', password: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [rememberMe, setRememberMe] = useState(true);
    const [username, setUsername] = useState('main');
    const [password, setPassword] = useState('123456');

    const onLogin = () => {
        let check = true;
        const errorObj = { ...errorsObj };
        if (!username) {
            errorObj.username = 'This field is required.';
            check = false;
        }
        if (!password) {
            errorObj.password = 'This field is required.';
            check = false;
        }
        setErrors(errorObj);
        if (check) {
            let data = {
                username: username,
                password: password,
                rememberMe: rememberMe
            }
            dispatch(loadingToggleAction(true));
            dispatch(loginAction(data, props.history, props.lang));
        }
    }

    const onChangeCheckbox = (e) => {
        setRememberMe(e.target.checked)
    }

    const onHandleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onLogin();
        }
    }

    return (
        <div className='body2'>
            <div className="container-1">
                <div>偶遇</div>
            </div>
            <div className="container-5">
                <div className='option'>接听其他人拨打的电话</div>
            </div>
            <div className="container-6">
                <div className='container-6-1'>
                    <div>今日偶遇</div>
                    <div>X</div>
                </div>
                <div className='container-6-2'>
                    <div className='container-6-2-1'>
                        <div className='avatar'></div>
                        <div>
                            <div className='name'>xx</div>
                            <div className='tags'>xx</div>
                        </div>
                    </div>
                    <div className='container-6-2-1'>
                        <div className='avatar'></div>
                        <div>
                            <div className='name'>xx</div>
                            <div className='tags'>xx</div>
                        </div>
                    </div>
                    <div className='container-6-2-1'>
                        <div className='avatar'></div>
                        <div>
                            <div className='name'>xx</div>
                            <div className='tags'>xx</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-7">
                <div>缘分一刻</div>
                <Row>
                    <Col xs={4}><div className='item'></div></Col>
                    <Col xs={4}><div className='item'></div></Col>
                    <Col xs={4}><div className='item'></div></Col>
                    <Col xs={4}><div className='item'></div></Col>
                    <Col xs={4}><div className='item'></div></Col>
                    <Col xs={4}><div className='item'></div></Col>
                    <Col xs={4}><div className='item'></div></Col>
                    <Col xs={4}><div className='item'></div></Col>
                </Row>
            </div>
            <div className='footer-navbar'>
                <Link to={`/meet`} className='nav'>偶遇</Link>
                <Link to={`/party`} className='nav'>星球</Link>
                <Link to={`/party`} className='nav'>派对</Link>
                <Link to={`/party`} className='nav'>消息</Link>
                <Link to={`/party`} className='nav'>我的</Link>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
        showLoading: auth.showLoading,
    }
}

export default connect(mapStateToProps)(Meet);
