import swal from 'sweetalert';
import {
    formatError,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp
} from '../../services/AuthService';
import { api } from '../../utils/api';
import { translate } from '../../utils/translate';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

export function signupAction(email, password, history) {
    return (dispatch) => {
        signUp(email, password)
            .then((response) => {
                saveTokenInLocalStorage(response.data);
                runLogoutTimer(
                    dispatch,
                    response.data.expiresIn * 1000,
                    history,
                );
                dispatch(confirmedSignupAction(response.data));
                history.push('/dashboard');
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(signupFailedAction(errorMessage));
            });
    };
}

export function logout() {
    // if (path) {
    //     if (path.includes('/login') || path.includes('/register') || path.includes('/forgot-password') || path.includes('/success') || path.includes('/test')) {
    //     } else {
    //         // history.push('/login');
    //     }
    // } else {
    //     // history.push('/login');
    // }
    localStorage.removeItem('pms-crm');
    localStorage.removeItem('pms-timer');
    return {
        type: LOGOUT_ACTION,
    };
}

// export function loginAction(email, password, history, rememberMe, lang) {
//     return (dispatch) => {
//         login(email, password)
//             .then((response) => {
//                 saveTokenInLocalStorage(response.data, rememberMe);
//                 if(!rememberMe){
//                     runLogoutTimer(
//                         dispatch,
//                         response.data.expiresIn * 1000,
//                         history,
//                     );
//                 }          

//                 dispatch(loginConfirmedAction(response.data));
// 				history.push('/dashboard');                
//             })
//             .catch((error) => {
// 				//console.log(error);
//                 const errorMessage = formatError(error.response.data);
//                 dispatch(loginFailedAction(errorMessage));
//             });
//     };
// }

export function loginAction(inputData, history, lang) {
    return (dispatch) => {
        // if (inputData.username === 'demo' && inputData.password === 'demo123') {
        //     let data = {
        //         id_token: "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJwZWl3ZW4uZG90aG91ekBnbWFpbC5jb20iLCJhdXRoIjoiUk9MRV9DUk0iLCJqdGkiOiI0ZWY4OWM3MGRiZjQ0OWM4ODA3YmE0ZDJlOTUwMTBhNSIsImV4cCI6MTcwMDU1OTEwNn0.FTeyRFiF8KONWtsMs-GbQqOcFcPSpZ7tCDYKEBNBuFu0Q9Cln9Zlj9n9Goxy4WBpduKBaFbbHgC6jchmYfX1dQ"
        //     }
        //     saveTokenInLocalStorage(data, inputData.rememberMe);
        //     dispatch(loadingToggleAction(true))
        //     dispatch(loginConfirmedAction(data));
        //     history.push('/dashboard');
        // } else {
        //     swal(translate(lang, 'Error'), 'Invalid email or password', "error");
        //     const errorMessage = translate(lang, 'Invalid email or password');
        //     dispatch(loginFailedAction(errorMessage));
        //     dispatch(loadingToggleAction(false))
        // }
        let data = {
            "username": inputData.username,
            "password": inputData.password,
            "remember": inputData.rememberMe
        };
        fetch(api.login, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": lang.toLowerCase()
            }),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(responseJson => {
                if (responseJson.status === 'success') {
                    saveTokenInLocalStorage(responseJson.data, inputData.rememberMe);
                    dispatch(loadingToggleAction(true))
                    dispatch(loginConfirmedAction(responseJson.data));
                    // history.push('/dashboard');
                } else {
                    swal(translate(lang, 'Error'), responseJson.message, "error");
                    const errorMessage = translate(lang, 'Invalid email or password');
                    dispatch(loginFailedAction(errorMessage));
                    dispatch(loadingToggleAction(false))
                }
            }).catch(error => {
                console.error("error", error);
            });
    };
}


export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
