import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { faKey, faSpinner, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import AuthSocialButtons from 'components/common/AuthSocialButtons';
import { Col, Form, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import AuthCardLayout from 'layout/AuthCardLayout';
import { translate } from 'utils/translate';
import { useDispatch } from 'react-redux';
import { loadingToggleAction, loginAction } from 'store/actions/AuthActions';
import Logo from 'components/common/Logo';
import Peer from 'peerjs';
import io from 'socket.io-client';

const socket = io(process.env.REACT_APP_WS_URL, { transports: ['websocket'] });

const Room = (props) => {
    const { roomId } = useParams();
    const [peers, setPeers] = useState(new Set());
    const myAudioRef = useRef(null);
    const peerAudioRefs = useRef({});

    useEffect(() => {
        const peer = new Peer();

        peer.on('open', id => {
            console.log(`My peer ID is: ${id}`);
            socket.emit('join-room', roomId, id);
        });

        // Audio constraints to improve quality and reduce echo
        const audioConstraints = {
            audio: {
                echoCancellation: true,
                noiseSuppression: true,
                autoGainControl: true
            }
        };

        navigator.mediaDevices.getUserMedia(audioConstraints)
            .then(stream => {
                console.log('Got media stream:', stream);
                if (myAudioRef.current) {
                    myAudioRef.current.srcObject = stream;
                    // myAudioRef.current.muted = true; // Mute local audio playback
                    myAudioRef.current.play(); // Ensure local audio playback starts muted
                }

                peer.on('call', call => {
                    console.log('Answering call from:', call.peer);
                    call.answer(stream);
                    call.on('stream', userAudioStream => {
                        console.log('Received stream from:', call.peer);
                        if (!peerAudioRefs.current[call.peer]) {
                            peerAudioRefs.current[call.peer] = React.createRef();
                            setPeers(prevPeers => new Set(prevPeers.add(call.peer)));
                        }
                        if (peerAudioRefs.current[call.peer].current) {
                            peerAudioRefs.current[call.peer].current.srcObject = userAudioStream;
                            peerAudioRefs.current[call.peer].current.play();
                        }
                    });
                });

                peer.on('error', err => {
                    console.log('peer error:', err);
                });

                socket.on('user-connected', peerId => {
                    console.log(`User connected: ${peerId}`);
                    const call = peer.call(peerId, stream);
                    call.on('stream', userAudioStream => {
                        console.log('Connected user stream:', userAudioStream);
                        if (!peerAudioRefs.current[call.peer]) {
                            peerAudioRefs.current[call.peer] = React.createRef();
                            setPeers(prevPeers => new Set(prevPeers.add(call.peer)));
                        }
                        if (peerAudioRefs.current[call.peer].current) {
                            peerAudioRefs.current[call.peer].current.srcObject = userAudioStream;
                            peerAudioRefs.current[call.peer].current.play();
                        }
                    });
                    call.on('close', () => {
                        console.log(`Call closed with: ${peerId}`);
                        setPeers(prevPeers => {
                            const newPeers = new Set(prevPeers);
                            newPeers.delete(peerId);
                            return newPeers;
                        });
                    });
                });

                socket.on('user-disconnected', peerId => {
                    console.log(`User disconnected: ${peerId}`);
                    setPeers(prevPeers => {
                        const newPeers = new Set(prevPeers);
                        newPeers.delete(peerId);
                        return newPeers;
                    });
                    if (peerAudioRefs.current[peerId]) {
                        delete peerAudioRefs.current[peerId];
                    }
                });

            }).catch(err => {
                console.error('Failed to get media stream:', err);
            });

        return () => {
            peer.destroy();
            socket.off('user-connected');
            socket.off('user-disconnected');
        };
    }, [roomId]);

    return (
        <div className='body3'>
            <div className="body3-overlay"></div>
            <div className="r-c-1">

            </div>
            <Row className="r-c-2">
                {[...peers].map(peerId => (
                    <Col key={`a_${peerId}`} xs={3}>
                        <div className={`avatar`}></div>
                    </Col>
                ))}
                <Col xs={3}>
                    <div className='avatar active'></div>
                </Col>

            </Row>
            <div className='r-c-3'>
                <div className='r-c-3-1'>
                    <div className="message"><br /></div>
                    <div className="message">兵哥 (永久) 50000+全麦2000</div>
                    <div className="message"><hr /></div>
                    <div className="message"></div>
                    <div className="message"></div>
                    <div className="message"></div>
                    <div className="message">厅务:</div>
                    <div className="message">张杰 (ID 10000001)</div>
                    <div className="message">阿庆 (ID 10000002)</div>
                    <div className="message">宇恒 (ID 10000003)</div>
                </div>
            </div>
            <div>
                <audio ref={myAudioRef} autoPlay />
                {[...peers].map(peerId => (
                    <audio key={peerId} ref={el => peerAudioRefs.current[peerId] = el} autoPlay />
                ))}
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
        showLoading: auth.showLoading,
    }
}

export default connect(mapStateToProps)(Room);
